<template>
  <div>
    <v-container v-if="componentKey" class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col cols="12" class="pl-0 pr-0 pb-1">
              <v-row>
                <v-spacer />

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="santralModel"
                    :items="santralDataList"
                    label="Santral"
                  />
                </v-col>

                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-select
                    v-model="measureSelect"
                    :items="measures"
                    label=""
                  />
                </v-col>
                <v-col cols="3" sm="2" md="2" class="pr-3">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker v-model="startDate" no-title scrollable>
                      <v-spacer />
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3" sm="1" md="1" class="pr-3">
                  <v-btn color="primary" @click="getData"> Filtrele </v-btn>
                </v-col>
              </v-row>
            </v-col>

            <v-col :key="componentKey" cols="12">
              <echart
                id="heatmapsHourlySS"
                class-name="widget-content"
                :height="height"
                width="100%"
                :chart-data="HeatmapData"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as Heatmap from "@/api/Inverter/Heatmap";
import moment from "moment";
import i18n from "@/locale/index";
import Echart from "@/views/components/Echart/Chart.vue";
import { getInverterList } from "@/api/Request/inverter";
export default {
  name: "Dashboard",
  components: {
    Echart,
  },
  data: () => ({
    startDate: moment().format("YYYY-MM-DD"),
    finishDate: moment().format("YYYY-MM-DD"),
    riseze: "false",
    menu: false,
    modal: false,
    menu2: false,
    mina: "",
    maxa: "",
    dialog: false,
    nowDate: new Date().toISOString().slice(0, 10),

    menu1: false,
    menu2: false,
    minDate: "",
    menu: false,
    daterangetext: i18n.t("Tarih Seç"),
    selecteditemstext: "",
    mindate: "2010",
    all: i18n.t("All"),
    dateRules: [(v) => !!v || "Date is required"],
    dates: [],

    measureList: [],
    santral_items: [],

    minutesDailyEnergydate: new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    measureList: [],
    measures: [],
    measureSelect: "ACPower",
    componentKey: 1,
    title: "",
    santralModel: "",
    inverterList: [],
    height: "",
    componentKey: 1,
    HeatmapData: [],
  }),
  computed: {
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (santralDataList === undefined) return;
      return santralDataList;
    },

    dateRangeText() {
      if (this.dates.length > 1) {
        const dateArr = this.dates;
        if (dateArr[0] > dateArr[1]) {
          this.selecteditemstext = `${dateArr[1]} ~ ${dateArr[0]}`;
        } else {
          this.selecteditemstext = `${dateArr[0]} ~ ${dateArr[1]}`;
        }
      } else {
        this.selecteditemstext = this.dates[0];
      }

      return this.selecteditemstext;
    },
    computedDateFormatted() {
      return this.formatDate();
    },
    maxDate() {
      return new Date().toISOString().substr(0, 10);
    },
  },
  watch: {
    santralModel() {
      if (this.santralModel !== null && this.santralModel !== undefined) {
        this.getData();
      }
    },
    measureSelect() {
      // this.getData();
    },
    $route: function (from, to) {
      this.riseze = "true";
    },
  },

  created() {},
  mounted() {
    this.startDate = moment().format("YYYY-MM-DD");
    this.finishDate = moment().format("YYYY-MM-DD");
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    function myTimer1() {
      const { santral } = self.$store.getters.selectedSantralModel;
      const StatsMeasure = self.$store.getters.santralInfo;

      if (santral !== undefined && Object.keys(StatsMeasure).length > 0) {
        self.santralModel = santral;
        self.santralInfo = StatsMeasure;

        const inverter = self.santralInfo[self.santralModel].INVERTER;

        const tempMeasureList = inverter.INVERTER01.general_measure_list;
        const measureList = [];
        const typeList = ["avg", "sum"];
    
        tempMeasureList.forEach((measure) => {
          if (typeList.indexOf(measure.formuletype) > -1) {
            measureList.push({
              text: i18n.t(measure.text),
              value: measure.value,
              unit: measure.unit,
              type: measure.type,
            });
          }
        });
        self.measures = measureList;

        clearInterval(myVar1);
      }
    }
  },

  methods: {
    getData() {
      this.title = `${moment()
        .add(-6, "day")
        .format("YYYY-MM-DD")} / ${moment().format("YYYY-MM-DD")} ${i18n.t(
        this.measureSelect
      )}`;

      const formdata = {
        condiniton: {
          santral: this.santralModel,
          startDate: this.startDate,
          finishDate: this.startDate,
          measures: this.measureSelect,
        },
      };
      const { inverterList, inverterInfo } = this.$store.getters;

      const inverterNameList = [];
      Object.keys(inverterInfo[this.santralModel]).forEach((item) => {
        inverterNameList.push(
          inverterInfo[this.santralModel][item].INVERTERNAME
        );
      });
      const inverterCount = inverterList[this.santralModel].length;

      this.height = "400px";

      if (inverterCount < 11) {
        this.height = "400px";
      }
      if (inverterCount > 10 && inverterCount < 15) {
        this.height = "600px";
      }

      if (inverterCount > 15 && inverterCount < 20) {
        this.height = "800px";
      }

      if (inverterCount > 20) {
        this.height = "900px";
      }
      this.componentKey++;
      const steptime = this.$store.getters.stepTime;
      let _s_measure;
      this.measures.forEach((v) => {
        if (this.measureSelect == v.value) {
          _s_measure = v;
        }
      });

      let tempdata = getInverterList(formdata);
      tempdata.then((res) => {
        const temp = Heatmap.default.heatmapHourly(
          res,
          _s_measure,
          inverterNameList,
          steptime
        );
        temp.then((res) => {
          this.HeatmapData = res;
        });
      });
    },
    onDateSelected(daterange) {
      this.finishDate = moment(daterange[1]._d).format("YYYY-MM-DD");
      this.startDate = moment(daterange[0]._d).format("YYYY-MM-DD");
      const alrt = this.select_filter();

      if (alrt != undefined) {
        alert(alrt);
      }
    },
    cancelF() {
      this.menu = false;
      this.daterangetext = i18n.t("Date Range");
      this.$refs.menu.save(this.dates);
    },
    save(date) {
      this.daterangetext = `${i18n.t("Date Range")} ${i18n.t(
        this.selectcolumn
      )}`;
      this.$refs.menu.save(date);
      this.loading = true;
      const selectdate = this.dates;

      if (date.length < 2) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[0];
      } else if (selectdate[0] > selectdate[1]) {
        this.finishDate = selectdate[0];
        this.startDate = selectdate[1];
      } else {
        this.finishDate = selectdate[1];
        this.startDate = selectdate[0];
      }
    },
  },
};
</script>

<style scoped>
#heatmapsHourlypage {
  position: relative;
  height: 350px;
  margin: 1px;
  padding: 1px;
}

#textrotate {
  text-align: center;
}

#heatmapsHourly {
  height: 95%;
}
</style>
